<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        a) What is the pH of a
        <number-value :value="concentration" unit="\text{M}" />
        solution of
        <chemical-latex content="HNO3(aq)?" />
      </p>

      <calculation-input
        v-model="inputs.pH"
        class="mb-5"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) I think this because:</p>

      <p class="mb-n5">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'Question138AI',
  components: {
    STextarea,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        pH: null,
        explainedResponse: null,
      },
    };
  },
  computed: {
    concentration() {
      return this.taskState.getValueBySymbol('concentration').content;
    },
  },
};
</script>
